<template>
  <div class="news_index">
    <page-header :title="$tc('news.name', 2)">
    </page-header>
    <page-content>
      <ntm-block>
        <template slot="options">
          <input type="text" class="form-control" :placeholder="$t('search')"  v-model="search">
          <button @click="createEmptyNewsItemAndEdit()" type="button" class="btn btn-sm btn-success">{{$t('action.addNew')}}</button>
        </template>
        <div class="table-responsive">
          <ntm-table :show-columns="showColumns" :data="model" @pageChange="setPage" :loading="loading">
            <template #actions="{row}">
              <le-copy-to-clipboard class="mr-1" :text="getNewsItemWebsiteUrl(row)"></le-copy-to-clipboard>
              <router-link :to="{ name: 'news.edit', params: {uuid: row.uuid }}" class="btn btn-sm btn-primary">
                <i class="fa fa-pencil"></i>
              </router-link>
              <button type="button"
                    class="btn btn-sm btn-danger"
                    @click="deleteNews(row.uuid)"><i class="fa fa-fw fa-trash"></i></button>
            </template>
          </ntm-table>
        </div>
      </ntm-block>
    </page-content>
  </div>
</template>
<script>
import basicIndexMixin from '@/mixins/basic.index.mixin'
import NewsService from '../../services/news.service'
import { useExternalLinks } from '@/use/useExternalLinks'
import { useCreateNewEntity } from '@/use/useCreateNewEntity'

export default {
  mixins: [basicIndexMixin],
  data () {
    return {
      showColumns: [
        {
          name: 'title',
          prop: 'title'
        },
        {
          name: 'publishDate',
          prop: 'publishDate'
        }
      ]
    }
  },
  setup () {
    const { getNewsItemWebsiteUrl } = useExternalLinks()
    const { createEmptyNewsItemAndEdit } = useCreateNewEntity()

    return {
      getNewsItemWebsiteUrl,
      createEmptyNewsItemAndEdit
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      NewsService.index(this.search, this.page).then((response) => {
        this.setData(response.data)
      })
    },
    deleteNews (uuid) {
      NewsService.destroy(uuid).then((response) => {
        setTimeout(() => {
          this.fetchData()
        }, 300)
      }).finally(() => {
        this.$store.commit('loaded')
      })
    }
  }
}
</script>
