<template>
  <div class="coach_index">
    <page-header :title="$tc('coach.name', 2)">
    </page-header>
    <page-content>
      <ntm-block>
        <template slot="options">
          <input type="text" class="form-control" :placeholder="$t('search')"  v-model="search">
          <button @click="createPage()" type="button" class="btn btn-sm btn-success">{{$t('action.addNew')}}</button>
        </template>
        <div class="table-responsive">
          <ntm-table :actions="actions" :show-columns="showColumns" :data="model" @pageChange="setPage" :loading="loading">
              <template slot="actions" slot-scope="{row}">
                <router-link :to="{ name: 'coaches.edit', params: {uuid: row.uuid }}" class="btn btn-sm btn-primary">
                              <i class="fa fa-pencil"></i>
                </router-link>
              </template>
          </ntm-table>
        </div>
      </ntm-block>
    </page-content>
  </div>
</template>
<script>
import CoachService from '@/services/coach.service'
import router from '@/router'
import basicIndexMixin from '@/mixins/basic.index.mixin'

export default {
  mixins: [basicIndexMixin],
  data () {
    return {
      showColumns: [
        {
          prop: 'profile',
          type: 'img'
        },
        {
          name: 'name',
          prop: 'name'
        },
        {
          name: 'yearOfBirth',
          prop: 'birthYear'
        }
      ],
      actions: [
        {
          icon: 'fa-pencil-square-o',
          route: {
            name: 'coaches.show',
            params: {
              uuid: 'uuid'
            }
          }
        }
      ]
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      CoachService.index(this.search, this.page).then((response) => {
        this.setData(response.data)
      })
    },
    createPage () {
      router.push({ name: 'coaches.create' })
    }
  }
}
</script>
