<template>
  <div class="user_index">
    <page-header :title="$tc('member.name', 2)">
    </page-header>
    <page-content>
      <ntm-block>
        <template slot="options">
<!--          <input type="text" class="form-control" :placeholder="$t('search')" v-model="search">-->
          <button @click="inviteListModal()" type="button" class="btn btn-sm btn-success">{{ $tc('invite.name', 2) }}</button>
          <button @click="addMemberModal()" type="button" class="btn btn-sm btn-success">{{ $t('member.add') }}</button>
        </template>
        <div class="table-responsive">
          <ntm-table :show-columns="showColumns" :data="model" @pageChange="setPage" :loading="loading" >
            <template slot="actions" slot-scope="{row}">
              <button v-if="user.uuid !== row.uuid && (user.isSuperAdmin || currentClient.role === 'ADMIN')" @click="editMember(row)" class="btn btn-sm btn-info">
                <i class="fa fa-pencil"></i>
              </button>
              <button v-if="user.uuid !== row.uuid && (user.isSuperAdmin || currentClient.role === 'ADMIN')" @click="deleteMember(row.id)" class="btn btn-sm btn-danger">
                <i class="fa fa-trash"></i>
              </button>
            </template>
          </ntm-table>
        </div>
      </ntm-block>
    </page-content>
    <modal name="invite-user" :adaptive="true" height="auto">
      <form @submit.prevent="sendInvite()">
        <ntm-block>
          <formly-form :form="invite.form" :model="invite.model" :fields="invite.fields"></formly-form>
          <div class="d-flex mb-2 justify-content-center">
            <button class="btn btn-success push-15 mr-2" :disabled="invite.loading"><span
              v-if="!invite.loading">{{ $t('invite.send') }}</span><i v-if="invite.loading"
                                                        class="fa fa-cog fa-spin"></i></button>
          </div>
        </ntm-block>
      </form>
    </modal>
    <modal name="invite-list" :adaptive="true" height="auto">
        <ntm-block>
          <ntm-table :show-columns="showInviteColumns" :data="invites" >
            <template slot="actions" slot-scope="{row}">
              <button v-if="user.uuid !== row.uuid && (user.isSuperAdmin || currentClient.role === 'ADMIN')" @click="resendInvite(row)" class="btn btn-sm btn-info">
                <i v-if="!row.isLoading" class="fa fa-send"></i><i v-if="row.isLoading" class="fa fa-cog fa-spin"></i>
              </button>
              <button v-if="user.uuid !== row.uuid && (user.isSuperAdmin || currentClient.role === 'ADMIN')" @click="deleteInvite(row)" class="btn btn-sm btn-danger">
                <i class="fa fa-trash"></i>
              </button>
            </template>
          </ntm-table>
        </ntm-block>
    </modal>
    <modal name="update-member" :adaptive="true" height="auto">
      <form @submit.prevent="updateMember()">
        <ntm-block :title="$t('member.edit')">
          <formly-form :form="updateMemberForm" :model="updateMemberModel" :fields="updateMemberFields"></formly-form>
          <button class="btn btn-success push-15" :disabled="updateMemberLoading"><span
            v-if="!updateMemberLoading">{{ $t('save') }}</span><i v-if="updateMemberLoading"
                                                                  class="fa fa-cog fa-spin"></i></button>
        </ntm-block>
      </form>
    </modal>
  </div>
</template>
<script>
import basicIndexMixin from '@/mixins/basic.index.mixin'
import MemberService from '@/services/member.service'
import InviteService from '@/services/invite.service'

export default {
  mixins: [basicIndexMixin],
  data () {
    return {
      invites: [],
      showInviteColumns: [
        {
          name: 'email',
          prop: 'email'
        },
        {
          name: 'role',
          prop: 'role'
        }
      ],
      updateMemberForm: {},
      updateMemberModel: {},
      updateMemberFields: [
        {
          key: 'role',
          type: 'ntm-select',
          options: ['ADMIN', 'MANAGER', 'SCOREKEEPER'],
          isString: true,
          templateOptions: {
            translated: true,
            label: 'role'
          }
        }
      ],
      updateMemberLoading: false,
      invite: {
        loading: false,
        form: {},
        fields: [
          {
            key: 'email',
            type: 'input',
            templateOptions: {
              label: 'email'
            }
          },
          {
            key: 'role',
            type: 'ntm-select',
            options: ['ADMIN', 'MANAGER', 'SCOREKEEPER'],
            isString: true,
            templateOptions: {
              translated: true,
              label: 'role'
            }
          }
        ],
        model: {
          email: null,
          role: null
        }
      },
      showColumns: [
        {
          name: 'name',
          prop: 'name'
        },
        {
          name: 'email',
          prop: 'email'
        },
        {
          name: 'role',
          prop: 'role'
        }
      ]
    }
  },
  mounted () {
    this.fetchData()
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    currentClient () {
      return this.$store.state.currentClient
    }
  },
  methods: {
    resendInvite (invite) {
      invite.isLoading = true
      InviteService.resend(invite.uuid).then(() => {
        this.fetchInvoiceData()
      }).finally(() => {
        invite.isLoading = false
      })
    },
    editMember (member) {
      this.updateMemberModel = member
      this.$modal.show('update-member')
    },
    updateMember () {
      this.updateMemberLoading = true

      MemberService.update(this.updateMemberModel.id, this.updateMemberModel).then(() => {
        this.$modal.hide('update-member')
        this.fetchData()
      }).finally(() => {
        this.updateMemberLoading = false
      })
    },
    deleteMember (uuid) {
      MemberService.delete(uuid).then(() => {
        this.fetchData()
      })
    },
    async inviteListModal () {
      await this.fetchInvoiceData()
      this.$modal.show('invite-list')
    },
    addMemberModal () {
      this.$modal.show('invite-user')
    },
    deleteInvite (invite) {
      InviteService.delete(invite.uuid).then(() => {
        this.fetchInvoiceData()
      })
    },
    sendInvite () {
      this.invite.loading = true

      InviteService.send(this.invite.model).then((res) => {
        this.$modal.hide('invite-user')

        this.invite.model = {
          email: null,
          role: null
        }

        if (res.data === 'member.added') {
          this.fetchData()
        }
      }).catch((err) => {
        this.invite.form.$errors = err.response.data.errors
      }).finally(() => {
        this.invite.loading = false
      })
    },
    fetchData () {
      MemberService.index(this.page).then((response) => {
        this.setData(response.data)
      })
    },
    async fetchInvoiceData () {
      this.invites = await InviteService.index()
    }
  }
}
</script>
