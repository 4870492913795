<template>
  <div class="club_index">
    <page-header :title="$tc('club.name', 2)">
    </page-header>
    <page-content>
      <ntm-block>
        <template slot="options">
          <input type="text" class="form-control" :placeholder="$tc('search')"  v-model="search">
          <button @click="createPage()" type="button" class="btn btn-sm btn-success">{{$t('action.addNew')}}</button>
          <button @click="exportAll()" type="button" :title="$t('action.export')" class="btn btn-sm btn-warning"> <i class="fa fa-file" aria-hidden="true"></i></button>
        </template>
        <div class="table-responsive">
          <ntm-table :show-columns="showColumns" :data="model" @pageChange="setPage" :loading="loading">
            <template slot="actions" slot-scope="{row}">
              <router-link :to="{ name: 'clubs.edit', params: {uuid: row.uuid }}" class="btn btn-sm btn-primary">
                <i class="fa fa-pencil"></i>
              </router-link>
            </template>
          </ntm-table>
        </div>
      </ntm-block>
      <!-- END Full Table -->
    </page-content>
  </div>
</template>
<script>
import ClubService from '@/services/club.service'
import router from '@/router'
import basicIndexMixin from '@/mixins/basic.index.mixin'
import ExportService from '../../services/export.service'
export default {
  mixins: [basicIndexMixin],
  data () {
    return {
      showColumns: [
        {
          name: 'name',
          prop: 'name'
        },
        {
          name: 'shortName',
          prop: 'shortName'
        },
        {
          name: 'owner',
          prop: 'owner'
        }
      ]
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      ClubService.index(this.search, this.page).then((response) => {
        this.setData(response.data)
      })
    },
    exportAll () {
      ExportService.clubs().then(res => {
        const fileURL = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'clubs.csv')
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
    createPage () {
      router.push({ name: 'clubs.create' })
    }
  }
}
</script>
