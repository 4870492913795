<template>
  <div class="sponsor_index">
    <page-header :title="$tc('sponsor.name', 2)">
    </page-header>
    <page-content>
      <ntm-block>
        <template slot="options">
<!--          <input type="text" class="form-control" :placeholder="$tc('search')"  v-model="search">-->
          <button @click="createEmptySponsorAndEdit()" type="button" class="btn btn-sm btn-success">{{$t('action.addNew')}}</button>
        </template>
        <div class="table-responsive">
          <ntm-table :show-columns="showColumns" :data="model" @pageChange="setPage" :loading="loading">
            <template slot="actions" slot-scope="{row}">
              <router-link :to="{ name: 'sponsors.edit', params: {uuid: row.uuid }}" class="btn btn-sm btn-primary">
                <i class="fa fa-pencil"></i>
              </router-link>
            </template>
          </ntm-table>
        </div>
      </ntm-block>
      <!-- END Full Table -->
    </page-content>
  </div>
</template>
<script>
import SponsorService from '@/services/sponsor.service'
import basicIndexMixin from '@/mixins/basic.index.mixin'
import { useCreateNewEntity } from '@/use/useCreateNewEntity'
export default {
  mixins: [basicIndexMixin],
  data () {
    return {
      showColumns: [
        {
          name: 'name',
          prop: 'name'
        }
      ]
    }
  },
  setup () {
    const { createEmptySponsorAndEdit } = useCreateNewEntity()

    return { createEmptySponsorAndEdit }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      SponsorService.index(this.search).then((response) => {
        this.setData(response.data)
      })
    }
  }
}
</script>
