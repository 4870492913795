import basicMixin from './basic.index.mixin'
const seasonIndexMixin = {
  mixins: [basicMixin],
  data () {
    return {
      model: []
    }
  },
  watch: {
    currentSeason: {
      handler: function () {
        this.loading = true
        this.fetchData()
      },
      immediate: true
    }
  },
  computed: {
    currentSeason () {
      return this.$store.state.user.currentSeason
    }
  }
}

export default seasonIndexMixin
