import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth.module'
import ApiService from '../services/api.service'
import VueI18n from '@/i18n'
import { getI18nDateTimeFormat } from '@/lib/i18nDateTimeFormat'
import DashboardService from '@/services/dashboard.service'
import UserService from '@/services/user.service'
import { useTimeline } from '@/use/useTimeline'
import { useImageRequest } from '@/use/useImageRequest'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth
  },
  state: {
    finishedGames: true,
    loading: true,
    isNavOpened: false,
    isMiniNavOpened: false,
    config: null,
    settings: null,
    ytThumbnails: null,
    user: {
      name: '',
      clients: []
    },
    currentSeason: {},
    domains: {},
    seasonOptions: [],
    availableLanguages: [],
    language: '',
    currentClient: null
  },
  mutations: {
    loading (state) {
      state.loading = true
    },
    loaded (state) {
      state.loading = false
    },
    setCurrentSeason (state, season) {
      state.currentSeason = season
    },
    async currentUser (state, user) {
      state.user = user
      state.currentSeason = user.currentSeason

      let client = null

      if (user.currentClientId) {
        const clientId = user.currentClientId

        client = state.user.clients.find(client => client.uuid === clientId)

        if (!client) {
          UserService.logout()
        }
      } else {
        client = state.user.clients.find(client => client.uuid === localStorage.getItem('le-client')) ?? state.user.clients[0]

        if (!client) {
          UserService.logout()
        }
      }

      return await this.dispatch('setClient', client)
    },
    updateClient (state, data) {
      const { fetchCurrentTimeline } = useTimeline()
      const { fetchImageRequestsCount } = useImageRequest()

      this.dispatch('fetchFinishedGames')
      fetchCurrentTimeline()
      fetchImageRequestsCount()

      state.currentSeason = data.currentSeason
      state.config = data.config
      state.settings = data.settings

      VueI18n.setDateTimeFormat('en', getI18nDateTimeFormat(state.settings.formatOfTime === '12h-clock'))
      VueI18n.setDateTimeFormat('sr', getI18nDateTimeFormat(state.settings.formatOfTime === '12h-clock'))
      VueI18n.setDateTimeFormat('sl', getI18nDateTimeFormat(state.settings.formatOfTime === '12h-clock'))

      state.domains = data.domains
    },
    setSeasonOptions (state, data) {
      state.seasonOptions = data
    }
  },
  getters: {
    isAdmin: state => state.currentClient?.role === 'ADMIN' || state.user?.isSuperAdmin,
    loading: state => state.loading
  },
  actions: {
    async setClient ({ state }, client) {
      const { timeline } = useTimeline()
      const { imageRequestsCount } = useImageRequest()

      timeline.value = null
      imageRequestsCount.value = null

      state.currentClient = client
      localStorage.setItem('le-client', client.uuid)
      ApiService.setClient(client.uuid)

      await this.dispatch('getClient')

      return client
    },
    getClient ({ commit }) {
      ApiService.get('api/v2/admin/client/config').then(response => {
        commit('updateClient', response.data)
      })
    },
    getAvailableLanguages ({ state }) {
      state.availableLanguages = require('../../../lm-locales/config/languages.json')
    },
    initLanguage (store) {
      let lang

      if (localStorage.getItem('locale')) {
        lang = localStorage.getItem('locale')
      } else {
        lang = 'en'
      }

      store.dispatch('setLanguage', lang)
    },
    setLanguage ({ state }, lang) {
      state.language = lang
      VueI18n.locale = lang
      localStorage.setItem('locale', lang)
    },
    fetchFinishedGames ({ state }) {
      DashboardService.finishedGames().then(res => {
        state.finishedGames = res.data
      })
    },
    toggleMiniNav ({ state }) {
      state.isMiniNavOpened = !state.isMiniNavOpened
    },
    toggleNav ({ state }) {
      state.isNavOpened = !state.isNavOpened
    },
    closeNav ({ state }) {
      state.isNavOpened = false
    },
    getSeasonOptions ({ commit }, q = '') {
      const search = q || ''
      ApiService.get(`api/v1/admin/search/seasons?search=${search}`).then(response => commit('setSeasonOptions', response.data))
    }
  }
})

export default store
export const useStore = () => store
