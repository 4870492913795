import ApiService from './api.service'

const NewsService = {
  index (search = '', page = 1) {
    return ApiService.get('api/v1/admin/news', {
      params: {
        search,
        page
      }
    })
  },

  show (uuid) {
    return ApiService.get('api/v1/admin/news/' + uuid)
  },

  update (uuid, data) {
    return ApiService.put('api/v1/admin/news/' + uuid, data)
  },

  store (data = {}) {
    return ApiService.post('api/v1/admin/news', data)
  },

  destroy (uuid) {
    return ApiService.delete('api/v1/admin/news/' + uuid)
  }
}

export default NewsService
