<template>
  <div class="player_index">
    <page-header :title="$tc('player.name', 2)">
    </page-header>
    <page-content>
      <ntm-block>
        <div class="table-responsive">
          <ntm-table :actions="actions" :show-columns="showColumns" :data="model" @pageChange="setPage" :loading="loading">
              <template slot="actions" slot-scope="{row}">
                <router-link :to="{ name: 'imageRequests.show', params: {uuid: row.uuid }}" class="btn btn-sm btn-primary">
                              <i class="fa fa-fw fa-eye"></i>
                </router-link>
              </template>
          </ntm-table>
        </div>
      </ntm-block>
    </page-content>
  </div>
</template>
<script>
import ImageRequestService from '@/services/imageRequests.service'
import basicIndexMixin from '@/mixins/basic.index.mixin'

export default {
  mixins: [basicIndexMixin],
  data () {
    return {
      showColumns: [
        {
          prop: 'profile',
          type: 'img'
        },
        {
          name: 'name',
          prop: 'name'
        },
        {
          name: 'entity',
          prop: 'entity',
          type: 'entity'
        }
      ],
      actions: [
        {
          icon: 'fa-pencil-square-o',
          route: {
            name: 'imageRequests.show',
            params: {
              uuid: 'uuid'
            }
          }
        }
      ]
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      ImageRequestService.index(this.page).then((res) => {
        this.setData(res.data)
      })
    }
  }
}
</script>
