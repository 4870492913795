import ApiService from './api.service'

const InviteService = {
  index () {
    return ApiService.get('api/v2/admin/invites')
  },
  send (data) {
    return ApiService.postWithoutLoading('api/v2/admin/invites/send', data)
  },
  delete (id) {
    return ApiService.deleteWithoutLoading('api/v2/admin/invites/' + id)
  },
  resend (id) {
    return ApiService.postWithoutLoading('api/v2/admin/invites/' + id + '/resend')
  }
}

export default InviteService
