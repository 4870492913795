<template>
  <div class="player_index">
    <page-header :title="$tc('player.name', 2)">
    </page-header>
    <page-content>
      <ntm-block>
        <template slot="options">
          <input type="text" class="form-control" :placeholder="$t('search')" v-model="search">
          <button @click="createPage()" type="button" class="btn btn-sm btn-success">{{$t('action.addNew')}}</button>
          <button @click="mergePage()" type="button" class="btn btn-sm btn-warning"><i class="fa fa-code-fork" aria-hidden="true"></i></button>
          <button @click="exportAll()" type="button" :title="$t('action.export')" class="btn btn-sm btn-warning"> <i class="fa fa-file" aria-hidden="true"></i></button>
        </template>
        <div class="table-responsive">
          <ntm-table :actions="actions" :show-columns="showColumns" :data="model" @pageChange="setPage" :loading="loading">
              <template slot="actions" slot-scope="{row}">
                <router-link :to="{ name: 'players.edit', params: {uuid: row.uuid }}" class="btn btn-sm btn-primary">
                              <i class="fa fa-pencil"></i>
                </router-link>
              </template>
          </ntm-table>
        </div>
      </ntm-block>
    </page-content>
  </div>
</template>
<script>
import PlayerService from '@/services/player.service'
import router from '@/router'
import basicIndexMixin from '@/mixins/basic.index.mixin'
import ExportService from '../../services/export.service'

export default {
  mixins: [basicIndexMixin],
  data () {
    return {
      showColumns: [
        {
          prop: 'profile',
          type: 'img'
        },
        {
          name: 'name',
          prop: 'name'
        },
        {
          type: 'option',
          name: 'position',
          prop: 'position'
        },
        {
          name: 'yearOfBirth',
          prop: 'birthYear'
        }
      ],
      actions: [
        {
          icon: 'fa-pencil-square-o',
          route: {
            name: 'players.show',
            params: {
              uuid: 'uuid'
            }
          }
        }
      ]
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      PlayerService.index(this.search, this.page).then((response) => {
        this.setData(response.data)
      })
    },
    exportAll () {
      ExportService.players().then(res => {
        const fileURL = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'players.csv')
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
    createPage () {
      router.push({ name: 'players.create' })
    },
    mergePage () {
      router.push({ name: 'players.merge' })
    }
  }
}
</script>
