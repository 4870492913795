<template>
  <div class="about">
    <page-header :title="$t('team.edit')" :desc="model.name">
      <li class="breadcrumb-item">
        <router-link :to="{name:'schedules.index'}">{{ $tc('schedule.name', 2) }}</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link :to="{name:'schedules.show', params: { uuid: model.schedule.uuid }}">{{ model.schedule.name }}
        </router-link>
      </li>
      <li class="breadcrumb-item">{{ $tc('team.name', 2) }}</li>
      <li class="breadcrumb-item">{{ model.name }}</li>
    </page-header>
    <page-content>
      <div class="row">
        <div class="col-xl-6">
          <ntm-block :title="$tc('image.name', 1)" :init-collapse="false">
            <ntm-crop :aspect-ratio="16/10" :max-width="1280" :max-height="800" conversion="lg" entity-type="team" :entity-uuid="$route.params.team" collection="profile"></ntm-crop>
          </ntm-block>
          <ntm-block :title="$t('image.jersey')" :init-collapse="false">
            <ntm-crop :aspect-ratio="3/4" :max-width="600" :max-height="800" conversion="lg" entity-type="team" :entity-uuid="$route.params.team" collection="jersey"></ntm-crop>
          </ntm-block>
          <form @submit.prevent="onSubmit()">
            <ntm-block :title="model.name">
              <formly-form :form="form" :model="model" :fields="fields"></formly-form>
              <div class="btn-group mb-3">
                <button class="btn btn-success">{{ $t('save') }}</button>
              </div>
            </ntm-block>
          </form>
        </div>
        <div class="col-xl-6">
          <ntm-block :title="$tc('player.name', 2)">
            <template #options>
              <button @click="showAddPlayersFromClubTeamsModal()" :disabled="!clubTeams.length" class="btn btn-info btn-sm">{{ $t('team.addPlayersFromClubTeams') }}</button>
              <button @click="addPlayer = true" class="btn btn-info btn-sm">{{ $t('player.add') }}</button>
            </template>
            <div class="row">
              <div class="col-12">
                <div class="form-group formly-input">
                  <v-select @search="searchPlayer" :filter="(options) => options" @option:selected="selectPlayer"
                            v-model="selectedPlayer" :options="playerFilteredOptions" inputId="playerSearch" label="name"
                            :placeholder="$tc('player.searchAndAdd')">
                    <template #option="option">
                      {{ option.name }}
                    </template>
                    <template #no-options>
                      <p class="info">{{ $tc('player.searchAndAdd') }}</p>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="col-12">
                <div class="table-responsive">
                  <ntm-table :show-columns="showColumns" :data="selectedPlayersForTable"
                             >
                    <template v-slot:actions="{row}">
                      <button @click="deletePlayerFromTeam(row.uuid)" class="btn btn-sm btn-danger">
                        <i class="fa fa-fw fa-trash"></i>
                      </button>
                    </template>
                  </ntm-table>
                </div>
              </div>
            </div>
          </ntm-block>
        </div>
      </div>
      <ntm-block :title="$tc('action.name', 2)">
        <template slot="options">
          <button type="button"
                  @click="deleteTeam(model.uuid)"
                  class="btn btn-sm btn-danger">{{ $t('team.delete') }}
          </button>
        </template>
      </ntm-block>
    </page-content>
    <ntm-sidebar :title="$t('player.create')" name="add-player">
      <form id="playerForm" @submit.prevent="onPlayerSubmit()">
        <formly-form :form="playerForm" :model="playerModel" :fields="playerFields"></formly-form>
        <button class="btn btn-success push-15">{{ $t('save') }}</button>
      </form>
    </ntm-sidebar>
    <modal class="add-players-from-club-teams-modal" name="add-players-from-club-teams" height="auto">
      <div class="p-3 content" v-if="!selectedClubTeam">
        <ntm-table :show-columns="showClubTeamsColumns" :data="clubTeamsForTable"
                   >
          <template v-slot:actions="{row}">
            <button @click="selectClubTeam(row)" class="btn btn-sm btn-info"><i class="fa fa-arrow-right"></i></button>
          </template>
        </ntm-table>
      </div>
      <div class="p-3 content" v-if="selectedClubTeam">
        <div class="d-flex justify-content-between mb-2">
          <button @click="selectedClubTeam = null; selectedClubTeamPlayers = [];" class="btn btn-sm btn-info"><i class="fa fa-arrow-left"></i></button>
          <h3 class="text-center mb-2 mt-2">{{selectedClubTeam.schedule.league.name}} - {{selectedClubTeam.schedule.season.name}}</h3>
        </div>
        <ntm-table :show-columns="showColumns" :data="selectedClubTeamPlayersForTable"
                   >
          <template v-slot:actions="{row}">
            <button @click="selectPlayer(row)" class="btn btn-sm btn-success"><i class="fa fa-plus"></i></button>
          </template>
        </ntm-table>
      </div>
    </modal>
  </div>
</template>

<script>
import fields from '../formDefinitions/editTeamFormDefinition'
import TeamService from '../../../services/team.service'
import seasonShowMixin from '../../../mixins/season.show.mixin'
import _ from 'lodash'
import VueI18n from '../../../i18n'
import playerFields from '@/views/players/formDefinition.js'
import { useRouteQuery } from '@/use/useRouteQuery'

export default {
  mixins: [seasonShowMixin],
  setup () {
    const addPlayer = useRouteQuery('add-player')
    return {
      addPlayer
    }
  },
  data () {
    return {
      playerForm: {},
      playerModel: {},
      playerFields: playerFields,
      form: {},
      model: {
        schedule: {}
      },
      fields: fields,
      playerOptions: [],
      selectedPlayers: [],
      selectedPlayer: null,
      selectedClubTeam: null,
      clubTeams: [],
      selectedClubTeamPlayers: [],
      showClubTeamsColumns: [
        {
          name: 'league',
          prop: 'schedule.league.name'
        },
        {
          name: 'season',
          prop: 'schedule.season.name'
        }
      ],
      showColumns: [
        {
          name: 'name',
          prop: 'name'
        }
      ]
    }
  },
  beforeRouteEnter (to, from, next) {
    Promise.all([TeamService.show(to.params.uuid, to.params.team), TeamService.getLatestClubTeams(to.params.uuid, to.params.team)])
      .then((response) => {
        next(vm => vm.setData(response))
      })
  },
  computed: {
    selectedPlayersUuids () {
      return this.selectedPlayers.map(player => player.uuid)
    },
    clubTeamsForTable () {
      return {
        data: this.clubTeams
      }
    },
    selectedPlayersForTable () {
      return {
        data: this.selectedPlayers
      }
    },
    selectedClubTeamPlayersForTable () {
      return {
        data: this.selectedClubTeamPlayers.filter((player) => {
          return !this.selectedPlayersUuids.includes(player.uuid)
        })
      }
    },
    playerFilteredOptions () {
      return this.playerOptions.filter((player) => {
        return !this.selectedPlayersUuids.includes(player.uuid)
      })
    }
  },
  methods: {
    selectClubTeam (team) {
      TeamService.getTeamPlayers(team.schedule.uuid, team.uuid).then((res) => {
        this.selectedClubTeam = team

        this.selectedClubTeamPlayers = res.data
      })
    },
    showAddPlayersFromClubTeamsModal () {
      this.selectedClubTeam = null
      this.selectedClubTeamPlayers = []
      this.$modal.show('add-players-from-club-teams')
    },
    deleteTeam () {
      TeamService.delete(this.$route.params.uuid, this.$route.params.team).then(() => this.$router.push({
        name: 'schedules.show',
        params: { uuid: this.$route.params.uuid }
      }))
    },
    selectPlayer (player) {
      this.selectedPlayer = null
      if (player.uuid === 'add') {
        this.addPlayer = true
        setTimeout(() => {
          const playerFormFirstName = document.querySelector('#playerForm .form-control')
          playerFormFirstName.focus()
        })
        return
      }
      TeamService.addPlayerToTeam(this.$route.params.uuid, this.$route.params.team, { playerUuid: player.uuid }).then(() => {
        this.getSelectedPlayers()
      }).catch((error) => {
        const playerUuidError = error.response.data.errors.playerUuid
        if (playerUuidError) {
          if (playerUuidError[0].includes('player.in_another_team')) {
            this.addPlayerFromAnotherTeam(playerUuidError[0], player)
          } else if (playerUuidError[0].includes('player.in_another_club')) {
            this.addPlayerFromAnotherClub(playerUuidError[0], player)
          }
        }
      }).finally(() => {
        this.focusPlayerSearch()
      })
    },
    addPlayerFromAnotherTeam (error, player) {
      const anotherTeamName = error.split('|')[1]
      return this.$swal.fire({
        title: VueI18n.t('swal.addPlayerFromAnotherTeam.title', { team: anotherTeamName }),
        text: VueI18n.t('swal.addPlayerFromAnotherTeam.text', { team: anotherTeamName }),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: VueI18n.t('swal.addPlayerFromAnotherTeam.yes'),
        cancelButtonText: VueI18n.t('swal.addPlayerFromAnotherTeam.no'),
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then((result) => {
        if (result.value) {
          return TeamService.addPlayerFromAnotherTeam(this.$route.params.uuid, this.$route.params.team, { playerUuid: player.uuid }).then(() => {
            this.getSelectedPlayers()
          })
        }
      })
    },
    addPlayerFromAnotherClub (error, player) {
      const anotherClubName = error.split('|')[1]
      const anotherClubLeagues = error.split('|')[2]
      return this.$swal.fire({
        title: VueI18n.t('swal.addPlayerFromAnotherClub.title', { club: anotherClubName, leagues: anotherClubLeagues }),
        text: VueI18n.t('swal.addPlayerFromAnotherClub.text', { club: anotherClubName }),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: VueI18n.t('swal.addPlayerFromAnotherClub.yes'),
        cancelButtonText: VueI18n.t('swal.addPlayerFromAnotherClub.no'),
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then((result) => {
        if (result.value) {
          return TeamService.addPlayerFromAnotherClub(this.$route.params.uuid, this.$route.params.team, { playerUuid: player.uuid }).then(() => {
            this.getSelectedPlayers()
          })
        }
      })
    },
    focusPlayerSearch () {
      setTimeout(() => {
        const searchInput = document.getElementById('playerSearch')
        searchInput.focus()
      })
    },
    setData (response) {
      this.model = response[0].data
      this.clubTeams = response[1].data
      this.fields[1].options = this.model.groups
      this.getSelectedPlayers()
    },
    searchPlayer (search, loading) {
      if (search.length > 0) {
        loading(true)
        this.searchClubPlayers(search, loading)
      } else {
        this.playerOptions = []
      }
    },
    searchClubPlayers: _.debounce(function (search, loading) {
      TeamService.searchClubPlayers(this.$route.params.uuid, this.$route.params.team, search).then((res) => {
        this.playerOptions = res.data
        if (!this.playerFilteredOptions.length) {
          this.playerOptions.push({ name: this.$t('player.add'), uuid: 'add' })
        }
        loading(false)
      })
    }, 300),
    getSelectedPlayers () {
      TeamService.getTeamPlayers(this.$route.params.uuid, this.$route.params.team).then((res) => {
        this.selectedPlayers = res.data
      })
    },
    deletePlayerFromTeam (playerUuid) {
      TeamService.deletePlayerFromTeam(this.$route.params.uuid, this.$route.params.team, playerUuid).then(() => {
        this.getSelectedPlayers()
      })
    },
    onSubmit () {
      TeamService.update(this.$route.params.uuid, this.$route.params.team, this.model).then(() => {
        this.$router.push({ name: 'schedules.show', params: { uuid: this.$route.params.uuid } })
      }).catch((err) => {
        this.form.$errors = err.response.data.errors
      })
    },
    onPlayerSubmit () {
      this.playerForm.$errors = {}
      TeamService.createAndAddNewPlayerToTeam(this.$route.params.uuid, this.$route.params.team, this.playerModel).then(() => {
        this.addPlayer = null
        this.playerModel = {}
        this.getSelectedPlayers()
        this.focusPlayerSearch()
      }).catch((err) => {
        this.playerForm.$errors = err.response.data.errors
      })
    }
  }
}
</script>
