import ApiService from './api.service'

const MemberService = {
  index (page = 1) {
    return ApiService.get(`api/v2/admin/members?page=${page}`)
  },
  update (id, data) {
    return ApiService.putWithoutLoading(`api/v2/admin/members/${id}`, data)
  },
  delete (id) {
    return ApiService.deleteWithoutLoading(`api/v2/admin/members/${id}`)
  }
}

export default MemberService
